/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
import { OidcConfiguration } from '@axa-fr/react-oidc';

export const configurationIdentityServer: OidcConfiguration = {
    client_id: import.meta.env.VITE_APP_OIDC_CLIENT_ID!,
    redirect_uri: `${window.location.origin}/authentication/callback`,
    silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`, // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore the session
    scope: 'openid profile email login.umdaschgroup.com/applications.read login.umdaschgroup.com/applications.write login.umdaschgroup.com/apiresources.read login.umdaschgroup.com/apiresources.write login.umdaschgroup.com/tenants.read login.umdaschgroup.com/tenants.write login.umdaschgroup.com/clients.read login.umdaschgroup.com/clients.write login.umdaschgroup.com/users.read login.umdaschgroup.com/users.write login.umdaschgroup.com/groups.read login.umdaschgroup.com/groups.write login.umdaschgroup.com/permissions.read login.umdaschgroup.com/permissions.write notifications.doka.com/notifications.inbox',
    authority: import.meta.env.VITE_APP_OIDC_AUTHORITY!,
    service_worker_relative_url: '/OidcServiceWorker.js',
    service_worker_convert_all_requests_to_cors: true,
    service_worker_only: true
};
