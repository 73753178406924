import { Stack, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useClientFormContext } from 'modules/applications/client/ClientFormProvider';
import { usePermissions } from 'modules/shared/permissions';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { ExtendedTextInput } from 'shared/components/inputs';
import { Switch } from 'shared/components/inputs/Switch';
import { ClientSecretGrid } from '../components/ClientSecretGrid';

type Props = DetailFormChildProps;

export const Security = (_: Props) => {
    const { t } = useTranslation();
    const form = useClientFormContext();
    const canUpdateOrCreate = usePermissions(['Update', 'Create']);

    const machine2MachineClient =
        form.values.basicInformation.allowedGrantTypes.clientCredentials || form.values.basicInformation.allowedGrantTypes.tokenExchange;

    return (
        <Stack mx="xs">
            <Switch
                description={t('enablePKCEDescription')}
                disabled={!canUpdateOrCreate}
                form={form}
                label={t('enablePKCE')}
                labelPosition="left"
                propertyPath="securitySettings.enablePKCE"
            />
            <ExtendedTextInput
                description={t('authorizationCodeLifetimeDescription')}
                form={form}
                label={t('authorizationCodeLifetime')}
                propertyPath="securitySettings.authorizationCodeLifetime"
                readOnly={!canUpdateOrCreate}
            />
            <Title order={4}>{t('token')}</Title>
            <ExtendedTextInput
                description={t('identityTokenLifetimeDescription')}
                form={form}
                iconDescription={t('timespanFormatDescription')}
                label={t('identityTokenLifetime')}
                propertyPath="securitySettings.identityTokenLifetime"
                readOnly={!canUpdateOrCreate}
            />
            <ExtendedTextInput
                description={t('accessTokenLifetimeDescription')}
                form={form}
                iconDescription={t('timespanFormatDescription')}
                label={t('accessTokenLifetime')}
                propertyPath="securitySettings.accessTokenLifetime"
                readOnly={!canUpdateOrCreate}
            />
            <Switch
                description={t('includeUserClaimsDescription')}
                disabled={!canUpdateOrCreate}
                form={form}
                label={t('includeUserClaims')}
                labelPosition="left"
                propertyPath="securitySettings.includeUserClaims"
            />
            <Switch
                description={t('refreshTokenEnabledDescription')}
                disabled={!canUpdateOrCreate}
                form={form}
                label={t('refreshTokenEnabled')}
                labelPosition="left"
                propertyPath="securitySettings.refreshTokenEnabled"
            />
            <ExtendedTextInput
                description={t('refreshTokenLifetimeDescription')}
                disabled={!form.values.securitySettings.refreshTokenEnabled}
                form={form}
                iconDescription={t('timespanFormatDescription')}
                label={t('refreshTokenLifetime')}
                propertyPath="securitySettings.refreshTokenLifetime"
                readOnly={!canUpdateOrCreate}
            />
            <Switch
                description={t('refreshTokenRotationDescription')}
                disabled={!form.values.securitySettings.refreshTokenEnabled || !canUpdateOrCreate}
                form={form}
                label={t('refreshTokenRotation')}
                labelPosition="left"
                propertyPath="securitySettings.refreshTokenRotation"
            />
            <ExtendedTextInput
                description={t('refreshTokenSlidingLifetimeDescription')}
                disabled={!form.values.securitySettings.refreshTokenEnabled || !form.values.securitySettings.refreshTokenRotation}
                form={form}
                iconDescription={t('timespanFormatDescription')}
                label={t('refreshTokenSlidingLifetime')}
                propertyPath="securitySettings.refreshTokenSlidingLifetime"
                readOnly={!canUpdateOrCreate}
            />
            <Title order={4}>{t('secrets')}</Title>
            <Switch
                description={t('requireClientSecretDescription')}
                disabled={machine2MachineClient || !canUpdateOrCreate}
                form={form}
                label={t('requireClientSecret')}
                labelPosition="left"
                propertyPath="securitySettings.requireClientSecret"
            />
            {form.values.securitySettings.requireClientSecret && form.values.basicInformation.id && <ClientSecretGrid />}
        </Stack>
    );
};
