import { FormErrors } from '@mantine/form';
import { useTranslation } from 'react-i18next';

import { DetailClientModel } from 'api/v3/models';
import { ClientFormProvider, clientFormSchema, ClientFormValues, useClientForm } from 'modules/applications/client/ClientFormProvider';
import { Permissions } from 'modules/applications/client/forms/Permissions';
import { usePermissions } from 'modules/shared/permissions';
import { DetailForm, DetailFormVariant } from 'shared/components/detailForm';
import { Accessibility } from './forms/Accessibility';
import { BasicInformation } from './forms/BasicInformation';
import { Security } from './forms/Security';
import { Uris } from './forms/Uris';
import { getFormClient } from './mappings';

type Props = {
    client?: DetailClientModel;
    onSubmit: (clientFormValues: ClientFormValues) => Promise<void>;
    onCancel?: () => void;
    variant?: DetailFormVariant;
    title?: string;
};

export const ClientForm = ({ client, variant = DetailFormVariant.CREATE, onSubmit, title }: Props) => {
    const { t } = useTranslation();
    const canUpdateOrCreate = usePermissions(['Update', 'Create']);

    const defaultClient = getFormClient(client);
    const form = useClientForm({
        initialValues: defaultClient
    });

    const handleConfirm = async () => {
        await onSubmit(form.values);
        form.resetDirty();
    };

    const handleReset = () => {
        form.setValues(defaultClient);
    };

    const machine2MachineClient =
        form.values.basicInformation.allowedGrantTypes.clientCredentials || form.values.basicInformation.allowedGrantTypes.tokenExchange;

    return (
        <ClientFormProvider form={form}>
            <DetailForm
                form={form}
                title={title}
                validationSchema={clientFormSchema}
                variant={variant}
                onConfirm={canUpdateOrCreate ? handleConfirm : undefined}
                onReset={handleReset}
            >
                <BasicInformation label={t('basicInformation')} name="basicInformation" schemaPath="basicInformation" variant={variant} />
                <Uris
                    disabled={machine2MachineClient}
                    label={t('uris')}
                    name="uris"
                    schemaPath="uriSettings"
                    variant={variant}
                    onCustomValidation={() => {
                        const errors: FormErrors = {};

                        if (form.values.basicInformation.allowedGrantTypes.authorizationCode) {
                            if (form.values.uriSettings.redirectUris.length === 0) {
                                errors['uriSettings.redirectUris'] = t('clientAuthorizationGrantRedirectUris');
                            }
                        }
                        return errors;
                    }}
                />
                <Accessibility
                    disabled={machine2MachineClient}
                    label={t('accessibility')}
                    name="accessibilitySettings"
                    schemaPath="accessibilitySettings"
                    variant={variant}
                />
                <Permissions label={t('permissions')} name="permissionSettings" schemaPath="permissionSettings" variant={variant} />
                <Security label={t('security')} name="securitySettings" schemaPath="securitySettings" variant={variant} />
            </DetailForm>
        </ClientFormProvider>
    );
};
