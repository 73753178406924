import { Link } from 'react-router-dom';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';

type Props = {
    breadcrumbs: BreadcrumbData[];
    maxElements?: number;
};

export const Breadcrumbs = ({ breadcrumbs, maxElements }: Props) => {
    const elements = !maxElements ? breadcrumbs : breadcrumbs.slice((maxElements + 1) * -1);

    return (
        <ul className="breadcrumbs -mt-4 -ml-2 sm:-ml-6 mb-xl pl-2xl pb-base border-b border-border-default">
            {elements.map((item, index, array) => (
                <li key={item.key}>
                    {index !== array.length - 1 ? (
                        <Link key={item.key} state={index <= 0 ? { preventRedirect: true } : undefined} to={item.key}>
                            {item.breadcrumb}
                        </Link>
                    ) : (
                        <span key={item.key}>{item.breadcrumb}</span>
                    )}
                </li>
            ))}
        </ul>
    );
};
